(() => {
  const angular = window.angular;

  angular.module('app').config(Router);

  Router.$inject = ['$stateProvider'];

  function Router($stateProvider) {
    $stateProvider.state('member.customer.show.project.responseProtocols', {
      url: '/response-protocols/:protocolId',
      component: 'responseProtocols',
      authenticated: true,
      params: {
        protocolId: {
          type: 'string',
          dynamic: true,
          squash: true,
        },
      },
      resolve: {
        module: [
          '$ocLazyLoad',
          function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/response-protocols.module.min.js',
            ]);
          },
        ],
        dependencies: [
          '$ocLazyLoad',
          '$rootScope',
          function ($ocLazyLoad, $root) {
            const style = $root.darkMode ? 'dark' : 'light';
            return $ocLazyLoad.load([
              window.assetsPath + '/js/vendors/editors/simplemde.min.js',
              window.assetsPath + `/css/editors-${style}.css`,
            ]);
          },
        ],
        protocols: [
          'Project',
          'project',
          function (Project, project) {
            return Project.prototype$__get__responseProtocols({
              id: project.id,
              filter: { fields: ['id', 'name'] },
            }).$promise;
          },
        ],
      },
      ncyBreadcrumb: {
        label: '{{"entities.tool.__tools.ResponseProtocol" | translate}}',
      },
    });
  }
})();
