(function () {
  const $ = window.$;

  BackgroundImage.$inject = ['$timeout', 'AssetService'];

  angular.module('app').directive('backgroundImage', BackgroundImage);

  function BackgroundImage($timeout, AssetService) {
    let defaultRatio = 1.77777777778;
    return {
      restrict: 'E',
      template: `<div class="bg-image-container">
            <div data-html2canvas-ignore ng-if="originalSize" style="position: absolute; top: 0; color: #333; right: 0; background: #fff; opacity: .5; padding: 0 2px" title="{{originalSize.width}}x{{originalSize.height}}">
              <strong>{{originalSize.width}}x{{originalSize.height}}</strong>
            </div>
            <div class="img-target" style="margin: auto; overflow-y: hidden"></div>
            <spinner show="loading && firstLoad" css-class="spinnerClass"></spinner>
        </div>`,
      scope: {
        asset: '=?',
        imageSrc: '<?',
        dimensions: '=?',
        onLoadContent: '=',
        maxHeight: '=?',
        spinnerClass: '=?',
        disableSpinner: '<?',
        imageMode: '<',
        refreshKey: '<?',
      },
      link: function (scope, element, attrs) {
        scope.container = element.find('.bg-image-container');
        scope.imageTarget = element.find('.img-target');
        scope.spinnerClass = scope.spinnerClass || 'xs light';
        scope.firstLoad = true;

        scope.$watch('imageSrc', (newVal) => {
          if (!scope.imageSrc) {
            return;
          }

          scope.dimensions = scope.dimensions || {};
          scope.loading = !scope.disableSpinner;
          scope.dimensions.loading = true;
          init(scope);
          scope.internalChange = false;
        });

        scope.$watch('maxHeight', (newVal) => {
          if (newVal) {
            calcContainerDimensions(scope);
            onLoadImage(scope, scope.image);
          }
        });

        scope.$watch('refreshKey', (newVal) => {
          if (newVal) {
            scope.loading = true;
            scope.firstLoad = true;
            renderImage(scope, scope.imageSrc);
          }
        });
      },
      controller: [
        '$scope',
        '$timeout',
        function ($scope, $timeout) {
          $(window).on('resize', onResize);

          $scope.$on('$destroy', () => {
            $scope.destroyed = true;
            $(window).off('resize', onResize);

            if ($scope.timeout) {
              $timeout.cancel($scope.timeout);
            }
          });

          function onResize() {
            if ($scope.timeout) {
              $timeout.cancel($scope.timeout);
            }
            $scope.timeout = $timeout(() => {
              if ($scope.destroyed) {
                return;
              }

              $scope.timeout = null;
              calcContainerDimensions($scope);
              onLoadImage($scope, $scope.image);
            }, 100);
          }
        },
      ],
    };

    function calcContainerDimensions(scope) {
      scope.imageTarget.hide();
      const width = scope.container.width();
      const height = scope.maxHeight || scope.container.height();

      scope.containerWidth = width > 150 ? Math.floor(width) : 150;
      scope.imageTarget.show();
      if (height < 150) {
        scope.containerHeight = scope.containerWidth / defaultRatio;
      } else {
        const ratio = width / height;

        if (ratio > defaultRatio) {
          scope.containerHeight = height;
          scope.containerWidth = height * defaultRatio;
        } else {
          scope.containerHeight = scope.containerWidth / defaultRatio;
        }
      }
      scope.imageTarget.width(scope.containerWidth);
      scope.imageTarget.height(scope.containerHeight);
    }

    function init(scope) {
      scope.dimensions = scope.dimensions || {};

      calcContainerDimensions(scope);
      const doNotRetry = scope.internalChange;
      renderImage(scope, scope.imageSrc, doNotRetry);
    }

    function renderImage($scope, src, doNotRetry) {
      const errorImage = window.assetsPath + '/images/no-image.png';
      let image = new window.Image();

      if ($scope.refreshKey) {
        src += src.indexOf('?') > -1 ? `&v=${$scope.refreshKey}` : `?v=${$scope.refreshKey}`;
      }

      image.src = src;
      image.crossOrigin = 'anonymous';
      image.onload = function () {
        if ($scope.destroyed) {
          return;
        }
        $timeout(() => {
          $scope.firstLoad = false;
          $scope.dimensions.hasError = $scope.imageSrc === errorImage;
          $scope.image = image;
          onLoadImage($scope, image);
          $scope.dimensions.loading = false;
          $scope.loading = false;
        }, 50);
      };
      image.onerror = function (err) {
        if (!doNotRetry) {
          $timeout(() => {
            $scope.firstLoad = false;
            if (!$scope.asset) {
              $scope.imageSrc = errorImage;
              return;
            }
            AssetService.reloadImageUrl($scope.asset, $scope.imageSrc)
              .then((url) => {
                $scope.imageSrc = url === $scope.imageSrc ? errorImage : url;
                $scope.internalChange = true;
              })
              .catch((err) => {
                $scope.imageSrc = errorImage;
                $scope.internalChange = true;
              });
          }, 1000);
        } else {
          $timeout(() => {
            $scope.firstLoad = false;
            $scope.dimensions.hasError = true;
            $scope.dimensions.loading = false;
            onLoadImage($scope, null);
            $scope.loading = false;
          });
        }
      };
    }

    function onLoadImage($scope, image) {
      $timeout(() => {
        if (!image) {
          if ($scope.onLoadContent) {
            $scope.onLoadContent(null, image);
          }
          return;
        }

        const size = {};

        $scope.dimensions.originalSize = $scope.originalSize = {
          height: image.naturalHeight,
          width: image.naturalWidth,
        };

        let imageRatio = image.naturalWidth / image.naturalHeight;
        const containerRatio = $scope.containerWidth / $scope.containerHeight;
        if (imageRatio > containerRatio) {
          size.width =
            image.naturalWidth > $scope.containerWidth ? $scope.containerWidth : image.naturalWidth;
          size.height = Math.floor((image.naturalHeight * size.width) / image.naturalWidth);
        } else {
          size.height =
            image.naturalHeight > $scope.containerHeight
              ? $scope.containerHeight
              : image.naturalHeight;
          size.width = Math.floor((image.naturalWidth * size.height) / image.naturalHeight);
        }

        $scope.dimensions.ratio = Math.round((size.width * 1000) / image.naturalWidth) / 1000;
        const left = ($scope.containerWidth - size.width) / 2;
        const top = ($scope.containerHeight - size.height) / 2;

        const imgContainer = $('<div style="overflow: hidden"/>');

        imgContainer.width(size.width);
        imgContainer.height(size.height);
        imgContainer.css({ marginTop: top, marginLeft: left });
        image.width = size.width;
        image.height = size.height;

        if ($scope.imageMode === 'canvas') {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = size.width;
          canvas.height = size.height;
          ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            size.width,
            size.height
          );
          imgContainer.append(canvas);
        } else {
          imgContainer.append(image);
        }

        $scope.imageTarget.empty();
        $scope.imageTarget.prepend(imgContainer);

        $scope.dimensions.width = size.width;
        $scope.dimensions.height = size.height;
        $scope.dimensions.offset = { top: top, left: left };

        if ($scope.onLoadContent) {
          $scope.onLoadContent($scope.dimensions.hasError, image);
        }
      });
    }
  }
})();
