(() => {
  const angular = window.angular;
  const $ = window.$;
  const $window = $(window);

  BasePaper.$inject = ['$rootScope', '$timeout', 'AppUtils'];

  angular.module('app').service('BasePaper', BasePaper);

  function BasePaper($root, $timeout, utils) {
    class BasePaperCanvas {
      constructor(container, targetElement, size, options = {}) {
        const { readOnly, style, context, ratio } = options;
        this.readOnly = typeof readOnly === 'boolean' ? readOnly : false;
        this.ratio = ratio === null || isNaN(ratio) ? 1 : ratio;
        this.single = options.single === true;
        this.targetElement = targetElement;
        this.container = container;

        this.PortClass = BasePaperPort;

        this.style = Object.assign(
          {
            stroke: '#fff',
            strokeWidth: 2,
            fillOpacity: 0.3,
          },
          style
        );

        this.context = context || '';

        let menu = document.getElementsByClassName('draw-menu');
        if (menu && menu.length) {
          menu[0].remove();
        }
        menu = document.createElement('UL');
        menu.classList.add('draw-menu');
        const element = targetElement[0];
        element.appendChild(menu);

        this.hideMenu = () => menu.classList.remove('opened');

        document.body.addEventListener('click', this.hideMenu);

        this.menu = menu;

        const paper = (this.paper = window.Raphael(targetElement[0], size.width, size.height));
        this.svg = paper.canvas;
        const drawArea = (this.drawArea = paper.rect(0, 0, size.width, size.height));

        this.tempPath = null;
        this.tempFigure = null;
        this.selectedFigure = null;
        this.figures = [];
        this.ports = [];

        drawArea.attr('fill', 'transparent');
        drawArea.attr('stroke', 'none');

        targetElement.find('svg').css({ marginLeft: (targetElement.width - size.width) / 2 });

        if (!this.readOnly) {
          drawArea.mousemove(this.onMouseMove());
          targetElement.click(this.onClick());
          targetElement.dblclick(this.onDblClick());
          this.container.bind('keydown', this.onKeydown());
        }
      }

      destroy() {
        this.paper.clear();
        this.targetElement.off();
        this.targetElement.empty();
        this.container.unbind('keydown');
        document.body.removeEventListener('click', this.hideMenu);
      }

      checkLimits(value, limit) {
        if (value <= 0) {
          value = 0;
        } else if (value > limit) {
          value = limit;
        }
        return value;
      }

      createFigure(sensorConfig) {
        sensorConfig.hidden =
          typeof sensorConfig.hidden === 'boolean' ? sensorConfig.hidden : false;
        let points = sensorConfig.points;
        if (!points) {
          return;
        }

        let path = [];

        const rect = this.svg.getBoundingClientRect();

        points.forEach((current, index) => {
          const x = this.checkLimits(this.applyRatio(current.x), rect.width);
          const y = this.checkLimits(this.applyRatio(current.y), rect.height);

          if (typeof x != 'number' || typeof y != 'number') {
            console.log('Invalid point', current);
          }

          path.push([index === 0 ? 'M' : 'L', x, y]);
        });
        path.push(['Z']);

        let figure = this.paper.path(path).attr({
          stroke: sensorConfig.color || this.style.stroke,
          fill: sensorConfig.color || this.style.stroke,
          'stroke-width': this.style.strokeWidth,
          'stroke-dasharray': '---',
          'fill-opacity': this.style.fillOpacity,
        });

        figure.canvas = this;
        figure.node.style.cursor = 'pointer';
        figure._sensor_config = sensorConfig;

        if (sensorConfig.hidden) {
          this.hideFigure(figure, true);
        }

        const _this = this;
        figure.click(function (e) {
          e.stopPropagation();
          _this.select(this);
        });

        return figure;
      }

      applyRatio(number) {
        const value = Math.round(number * this.ratio * 10000) / 10000;
        return value >= 0 ? value : 0;
      }

      unApplyRatio(number) {
        return Math.round(number / this.ratio);
      }

      onClick() {
        const _this = this;
        return function () {
          _this.menu.classList.remove('opened');
          const paper = _this.paper;
          const drawArea = _this.drawArea;
          _this.container.focus();
          if (_this.isDragging) {
            _this.isDragging = false;
          } else if (_this.selectedFigure) {
            _this.unSelect(_this.selectedFigure);
            _this.selectedFigure = null;
          } else {
            if (_this.single && _this.figures.length) {
              return;
            }

            if (!this.single) {
              _this.figures.forEach((current) => {
                current.attr({ fill: null });
              });
            }

            if (!_this.tempPath) {
              _this.tempPath = [];
              _this.unSelect(_this.selectedFigure);
            }
            if (_this.tempPath.length === 0) {
              _this.tempPath = [
                ['M', drawArea.ox, drawArea.oy],
                ['L', drawArea.ox, drawArea.oy],
              ];
              _this.tempFigure = paper.path(_this.tempPath);
              _this.tempFigure.attr({
                stroke: _this.style.stroke,
                'stroke-width': _this.style.strokeWidth,
                'stroke-dasharray': '-',
                'fill-opacity': _this.style.fillOpacity,
              });
            } else if (_this.tempFigure) {
              _this.afterClick();
            }
          }
        };
      }

      afterClick() {
        this.addPoint();
      }

      onDblClick() {}

      onMouseMove() {
        const _this = this;
        return function (event) {
          const IE = !!document.all;
          const tempPath = _this.tempPath;
          let x, y;
          if (IE) {
            x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
          } else {
            x = event.pageX;
            y = event.pageY;
          }

          this.ox = x - _this.targetElement.offset().left - $window.scrollLeft();
          this.oy = y - _this.targetElement.offset().top - $window.scrollTop();

          if (tempPath && tempPath.length) {
            _this.updateTempFigure(this.ox, this.oy);
          }
        };
      }

      onKeydown() {
        const _this = this;
        return function onKeydown(event) {
          let figure;
          switch (event.keyCode) {
            case 27: // ESC
              figure = _this.tempFigure;
              _this.tempFigure = null;
              _this.tempPath = null;
              $timeout(() => {
                _this.state = null;
              });
              break;
            case 46: // DELETE
              const sensorConfig = _this.selectedFigure._sensor_config || {};
              if (sensorConfig.triggers && sensorConfig.triggers.length) {
                return;
              }

              figure = _this.selectedFigure;
              _this.selectedFigure = null;
              break;
          }

          _this.figures.forEach((current) => {
            current.attr({
              fill:
                current._sensor_config && current._sensor_config.color
                  ? current._sensor_config.color
                  : this.style.stroke,
            });
          });

          if (figure) {
            $root.$emit('det_draw:remove' + _this.context, figure._sensor_config);
            _this.remove(figure);
            figure = null;
          }
        };
      }

      updateTempFigure(x, y) {
        if (isNaN(x) || isNaN(y)) {
          return;
        }

        const _this = this;
        const tempPath = this.tempPath;
        $timeout(() => {
          if (tempPath[tempPath.length - 1][1] === x && tempPath[tempPath.length - 1][2] === y) {
            return;
          }

          this.state = 'drawing';
          if (tempPath.length <= 2) {
            tempPath[tempPath.length - 1][1] = x;
            tempPath[tempPath.length - 1][2] = y;
          } else {
            if (Math.abs(x - tempPath[0][1]) < 8 && Math.abs(y - tempPath[0][2]) < 8) {
              if (tempPath[tempPath.length - 1][0] !== 'Z') {
                tempPath[tempPath.length - 1] = ['Z'];
              }
              this.state = 'close';
            } else if (tempPath[tempPath.length - 1][0] === 'Z') {
              tempPath[tempPath.length - 1] = ['L', x, y];
            } else {
              tempPath[tempPath.length - 1][1] = x;
              tempPath[tempPath.length - 1][2] = y;
            }
          }
          _this.tempFigure.attr({ path: tempPath });
        });
      }

      updateFigure(figure, config) {
        figure.attr({ 'stroke-dasharray': config.sensorId ? '---' : '-' });
        if (config.color) {
          figure.attr({ stroke: config.color, fill: config.color });
        }
      }

      addPoint() {
        let tempPath = this.tempPath;
        if (tempPath[tempPath.length - 1][0] === 'Z') {
          this.endFigure(this.tempFigure);
          tempPath = this.tempPath = null;
          this.tempFigure = null;
        } else {
          tempPath.push(['L', this.drawArea.ox, this.drawArea.oy]);
          this.tempFigure.attr({ path: tempPath });
        }
      }

      endFigure(figure) {
        let path = figure.getPath();
        path = this.fixPath(path);
        figure._currentPath = angular.copy(path);

        figure.attr({
          path: path,
          fill: this.style.stroke,
          'stroke-width': this.style.strokeWidth,
        });
        figure.node.style.cursor = 'pointer';

        const _this = this;
        figure.click(function (e) {
          e.stopPropagation();
          _this.select(this);
        });

        let points = [];
        for (let i = 0; i < path.length - 1; i++) {
          points.push({ x: this.unApplyRatio(path[i][1]), y: this.unApplyRatio(path[i][2]) });
        }

        figure.canvas = this;
        this.figures.push(figure);
        $timeout(() => {
          figure._sensor_config = {
            points: points,
            id: utils.generateUUID(),
            color: this.style.stroke,
          };
          $root.$emit('det_draw:add' + _this.context, figure._sensor_config);
          _this.state = null;
        });

        this.select(figure);
        this.tempPath = null;
        this.tempFigure = null;

        this.figures.forEach((current) => {
          current.attr({
            fill:
              current._sensor_config && current._sensor_config.color
                ? current._sensor_config.color
                : this.style.stroke,
          });
        });
      }

      remove(figure) {
        this.unSelect(figure);

        if (figure.children) {
          for (let child of figure.children) {
            child.remove();
          }
        }
        figure.remove();

        if (this.selectedFigure === figure) {
          this.selectedFigure = null;
        }

        let index = this.figures.findIndex((current) => current === figure);
        if (index !== -1) {
          this.figures.splice(index, 1);
        }
      }

      fixPath(path) {
        let fixedPath = [path[0]];
        for (let i = 1; i < path.length - 1; i++) {
          if (path[i][0] === path[i - 1][0] && path[i][1] === path[i - 1][1]) {
            continue;
          }
          fixedPath.push(path[i]);
        }

        fixedPath.push(['Z']);
        return fixedPath;
      }

      unSelect(figure) {
        if (figure) {
          if (figure.node) {
            figure.node.style.cursor = 'pointer';
          }
        }
        this.hidePorts();

        $timeout(() => {
          $root.$emit('det_draw:select' + this.context, null);
        });
      }

      select(figure) {
        if (figure === this.selectedFigure) {
          return;
        }

        if (this.selectedFigure) {
          this.selectedFigure.node.style.cursor = 'pointer';
          this.hidePorts();
        }

        if (!figure || !figure.node) {
          return;
        }

        this.hideMenu();
        this.selectedFigure = figure;

        figure.node.style.cursor = 'default';
        if (figure.node.style.display !== 'none' && !this.readOnly) {
          this.showPorts(figure);
        }

        $timeout(() => {
          $root.$emit('det_draw:select' + this.context, figure._sensor_config);
        });
      }

      hideFigure(figure, force) {
        if (figure.hidden && !force) {
          return;
        }
        figure.hide();
        if (figure === this.selectedFigure) {
          this.hidePorts(figure);
        }

        if (figure.children) {
          for (const child of figure.children) {
            child.hide();
          }
        }

        figure.hidden = true;
      }

      showFigure(figure) {
        if (!figure.hidden) {
          return;
        }
        figure.show();
        if (figure === this.selectedFigure) {
          this.showPorts(figure);
        }

        if (figure.children) {
          for (const child of figure.children) {
            child.show();
          }
        }

        figure.hidden = false;
      }

      hidePorts() {
        if (this.ports && this.ports.length) {
          this.ports.forEach((port) => {
            port.destroy();
          });

          this.ports = [];
        }
      }

      showPorts(figure) {
        let path = figure.attrs.path;
        let max = path[path.length - 1][0] === 'Z' ? path.length - 1 : path.length;

        for (let i = 0; i < max; i++) {
          let port = new this.PortClass(figure, i, this.style.port);
          this.ports.push(port);
        }
      }
    }

    // Port
    class BasePaperPort {
      constructor(target, index, style = {}) {
        this.style = Object.assign(
          {
            radius: 8,
            fill: '#4ab6f2',
            strokeWidth: 2,
            stroke: '#fff',
          },
          style
        );

        this.canvas = target ? target.canvas : null;

        this.index = index;
        this.target = target;

        let path = target.attrs.path;
        const x = path[index][1];
        const y = path[index][2];

        this.svg = this.canvas.paper.canvas;

        this.port = this.canvas.paper.circle(x, y, this.style.radius);
        this.port.attr({
          fill: this.style.fill,
          'stroke-width': this.style.strokeWidth,
          stroke: this.style.stroke,
        });

        this.port.click((e) => {
          e.preventDefault();
          e.stopPropagation();
        });

        this.port.drag(this.onMove(), this.onStart(), this.onEnd());

        this.destroy = function () {
          this.port.undrag();
          this.port.unclick();
          this.port.undblclick();
          this.port.remove();
        };

        this.attr = function (options) {
          this.port.attr(options);
        };
      }

      onStart() {
        const _this = this;
        return function onStart() {
          if (_this.canvas.readOnly) {
            return;
          }
          _this.target.attr({ fill: null });
          _this.canvas.isDragging = true;
          $timeout(() => {
            _this.canvas.state = 'drawing';
          });

          _this.canvas.figures.forEach((current) => {
            current.attr({ fill: null });
          });
        };
      }

      onEnd() {
        const _this = this;
        return function () {
          if (_this.canvas.readOnly) {
            return;
          }

          const fill =
            _this.target._sensor_config && _this.target._sensor_config.color
              ? _this.target._sensor_config.color
              : _this.canvas.style.stroke;

          _this.target.attr({ fill: fill });
          _this.canvas.figures.forEach((current) => {
            const fill =
              current._sensor_config && current._sensor_config.color
                ? current._sensor_config.color
                : _this.canvas.style.stroke;
            current.attr({ fill: fill });
          });

          $timeout(() => {
            _this.canvas.state = null;
          });

          _this.target._currentPath = _this.target.getPath().slice();
        };
      }

      checkLimits(value, limit) {
        let radius = this.style.radius * 2;
        if (value <= radius) {
          value = 0;
        } else if (value + radius >= limit) {
          value = limit;
        }
        return value;
      }

      onMove() {
        const _this = this;
        return function (event) {
          if (_this.canvas.readOnly) {
            return;
          }
          const pointIndex = _this.index;
          const target = _this.target;
          const drawArea = _this.canvas.drawArea;
          const rect = _this.svg.getBoundingClientRect();

          let x = _this.checkLimits(drawArea.ox, rect.width);
          let y = _this.checkLimits(drawArea.oy, rect.height);

          if (typeof x != 'number' || typeof y != 'number') {
            return;
          }

          let path = target.getPath();
          path[pointIndex][1] = x;
          path[pointIndex][2] = y;

          target.attr({ path: path });
          _this.attr({ cx: x, cy: y });
          $timeout(() => {
            if (target._sensor_config) {
              target._sensor_config.points[pointIndex] = {
                x: _this.canvas.unApplyRatio(x),
                y: _this.canvas.unApplyRatio(y),
              };
            }
          });
        };
      }
    }

    return {
      Canvas: BasePaperCanvas,
      Port: BasePaperPort,
    };
  }
})();
