(function () {
  'use strict';

  const angular = window.angular;
  ProfileController.$inject = ['$element', '$timeout', '$filter'];

  angular.module('app').component('telegramActiveSessions', {
    template:'<div style="position: relative" ng-style="{opacity: ctrl.loading? 0: 1}"><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()" aria-hidden="true"><i class="fas fa-times"></i></button> <span>Sesiones de telegram activas</span></div><div class="modal-body"><div><div class="panel panel-default"><div class="dataTable-toolbar paddingT10"><div class="btn-group btn-group-sm"><button class="btn btn-default btn-label disabled" translate>general.actions</button> <button class="btn btn-primary" style="min-width: 90px" ng-click="ctrl.deletePermission()" ng-disabled="!ctrl.selectedTelegramSession"><i class="fas fa-trash"></i> <span translate>buttons.delete</span></button></div></div><div style="padding-inline: 6px"><table id="sessions-table" class="table table-hover no-wrap"><thead><tr><th></th><th></th><th></th><th class="control"></th></tr></thead></table></div></div></div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.errors.length"><div ng-repeat="error in ctrl.errors">{{error}}</div></div><div class="modal-footer"><button class="btn btn-primary" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.done</button></div></div><spinner show="ctrl.loading" show-logo="true"></spinner></div>',
    controller: ProfileController,
    controllerAs: 'ctrl',
    bindings: {
      resolve: '<',
      modalInstance: '<',
    },
  });

  function ProfileController($element, $timeout, $filter) {
    let vm = this;
    vm.$onInit = function () {
      console.log(vm.resolve);
      initTable(vm.resolve.sessions);
    };

    function initTable(data) {
      if (vm.dtInstance) {
        vm.dtInstance.clear();
        vm.dtInstance.rows.add(data);
        vm.dtInstance.draw();
        return;
      }

      let dtOptions = {
        processing: true,
        paging: false,
        info: false,
        data: data,
        select: {
          selector: 'td:not(.control)',
          style: 'single',
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-radio-btn',
            render: checkboxRendered,
            width: '10px',
          },
          {
            data: 'platform',
            //title: $translate.instant('entities.telegram.titles.name'),
            title: 'Plataforma',
            width: 'auto',
          },
          {
            data: 'authDate',
            //title: $translate.instant('entities.telegram.titles.surname'),
            title: 'Fecha de login',
            width: 'auto',
            render: function (data, type, row) {
              console.log(data);
              return data != null ? $filter('date')(data, 'yyyy-MM-dd HH:mm') : '';
            },
          },
          {
            data: 'expiresAt',
            //title: $translate.instant('entities.telegram.titles.surname'),
            title: 'Fecha de Expiracion',
            width: 'auto',
            render: function (data, type, row) {
              return data != null ? $filter('date')(data, 'yyyy-MM-dd HH:mm') : '';
            },
          },
        ],
      };

      $timeout(() => {
        let table = $element.find('#sessions-table');

        vm.dtInstance = table.DataTable(dtOptions);
        let dtInstance = vm.dtInstance;

        table.find('thead th').removeClass('select-radio-btn');

        dtInstance
          .on('select', function () {
            $timeout(function () {
              vm.selectedTelegramSession = dtInstance.rows({ selected: true }).data().toArray()[0];
            });
          })
          .on('deselect', function () {
            $timeout(function () {
              vm.selectedTelegramSession = null;
            });
          });
      }, 50);
    }
    function checkboxRendered() {
      return '';
    }
  }
})();
