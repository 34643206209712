(() => {
  angular.module('app').component('activeDirectoryCallback', {
    template: `<spinner show="ctrl.loading"></spinner>`,
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      $transition$: '<',
    },
  });

  Controller.$inject = ['$state', '$rootScope', 'LoopBackAuth', 'Manager'];

  function Controller($state, $rootScope, LoopBackAuth, Manager) {
    const vm = this;

    vm.$onInit = () => {
      const token = vm.$transition$.params().token;
      const userId = vm.$transition$.params().user;

      $rootScope.appReady = false;

      if (!token || !userId) {
        $state.go('public.login');
        return;
      }

      LoopBackAuth.setUser(token, userId);
      Manager.findById({ id: userId })
        .$promise.then((user) => {
          LoopBackAuth.setUser(token, userId, user);
          LoopBackAuth.save();
          $rootScope.appReady = true;
          $state.go('member.home');
        })
        .catch((err) => {
          $rootScope.appReady = true;
          vm.error = err;
          console.log(err);
        });
    };
  }
})();
